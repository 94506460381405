import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Button, { ButtonProps } from "../Button/Button"
import "./Card.scss"
import chevron from "../../svg/chevron-down-black.svg"
import { navigate } from "gatsby"
interface CardProps {
  title: string
  text: string
  svgImage?: any
  button?: ButtonProps
}

const Card = ({ title, text, svgImage, button }: CardProps) => {
  return (
    <div
      className="Card"
      onClick={() => {
        button && navigate(button.link)
      }}
    >
      <div className="Card__image-wrap">
        <img src={svgImage} alt="icon" className="Card__image" />
      </div>

      <div className="Card__text-section">
        <h3 className="Card__title">{title}</h3>
        <p className="Card__text">{text}</p>
      </div>

      <div className="Card__button">
        {button && (
          <a href={button.link}>
            <img src={chevron} alt="Card icon" style={{ transform: "rotate(-90deg)" }}></img>
          </a>
        )}
      </div>
    </div>
  )
}

export default Card
