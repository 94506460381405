import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Section from "../components/Section/Section"
import Columns from "../components/Columns/Columns"
import MainSection from "../components/MainSection/MainSection"
import Card from "../components/Card/Card"
import "../styles/index.scss"
import QuoteSection from "../components/QuoteSection/QuoteSection"
import Button from "../components/Button/Button"
import VideoSection from "../components/VideoSection/VideoSection"
import Detect_Fraud from "../images/ilustrations/HomeTheme.svg"

import TransactionCards from "../images/ilustrations/idForensics.svg"
import Documents from "../svg/DocForensics.svg"
import Transactions from "../svg/TraForensics.svg"
import LetterR from "../images/r_ilustration.png"
import forgery from "../svg/Money.svg"
import fraud from "../svg/fraud.svg"
import financialCrime from "../svg/financial_crime.svg"

import logo_media_1 from "../images/media/media_1.svg"
import logo_media_2 from "../images/media/media_2.svg"
import logo_media_3 from "../images/media/media_3.svg"
import logo_media_4 from "../images/media/media_4.svg"
import logo_media_5 from "../images/media/media_5.svg"

import Fade from "react-reveal/Fade"

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Prevent emerging fraud. Detect forgeries. Manage and reduce false alerts."
        description="Resistant AI hardens your automated financial systems from unknown frauds and forgeries. Spend less time on false alarms, and more time growing your business."
      />
      <div className="Homepage__main">
        <MainSection
          className="Homepage__hero"
          showReferences
        >

          <div className="w-wrap">

            <div className="w1-2">
              <h1 className="MainSection__title">
                <span className="highlighted">Protects</span> Automation and Al Systems <span className="highlighted">From Manipulation and Attack</span>
              </h1>
              <p className="MainSection__text">
                Resistant Al subjects every customer interaction to forensic analysis to
                detect document forgery, serial fraud, synthetic identities, bots, account
                takeovers, money laundering, and unknown financial threats operating at scale.
              </p>
              <div className="MainSection__button">
                <Button {...{
                  link: "/contact",
                  variant: "blue",
                  text: "Get Started",
                  size: "large", }} />
              </div>
            </div>

            <div className="w1-2">
              <img alt="Detect fraud" src={Detect_Fraud} />
            </div>


          </div>

        </MainSection>
      </div>
      <Section className="Homepage__operations">

        <div>
            <h2>
              Taking the Offensive
              <span className="highlighted"> Against Financial Crime</span>
            </h2>
            <p>
              Smooth and instant decisions are key aspects to any modern financial service.
              Al systems that power such decisions are under constant attack by fraudsters
              capable of committing fraud with convenience and anonymity.
            </p>
        </div>

        <Fade up distance="100px">
          <Columns
            style={{ marginTop: 70 }}
            className="Homepage__columns"
            centered
          >
            <div className="Columns__with-image">
              <div className="Homepage__column-image">
                <img alt="Fraud" src={financialCrime} />
              </div>
              <div>
                <h3>Fraud</h3>
                <p>
                  Cost of fraud globally{" "}
                  <a
                    href="https://www.crowe.com/global/news/fraud-costs-the-global-economy-over-us$5-trillion"
                    target="_blank"
                  >
                    US$5 trillion p.a.
                  </a>
                </p>
              </div>
            </div>
            <div className="Columns__with-image">
              <div className="Homepage__column-image">
                <img alt="Financial crime" src={forgery} />
              </div>
              <div>
                  <h3>Financial Crime</h3>
                <p>
                  <a
                    href="https://www.europol.europa.eu/crime-areas-and-trends/crime-areas/economic-crime/money-laundering"
                    target="_blank"
                  >
                    Between 2% (€715Bn) and 5% (€1.87Tn)
                  </a>{" "}
                  of global GDP is laundered annually; only 1.1% is recovered
                </p>
              </div>
            </div>
            <div className="Columns__with-image">
              <div className="Homepage__column-image">
                <img alt="Forgery" src={fraud} />
              </div>
              <div>
                  <h3>Forgery</h3>
                <p>
                  Up to 1% of payment transactions and lending applications we
                  inspect are{" "}
                  <div className="Homepage__tooltip">
                    fraudulent
                    <span className="Homepage__tooltiptext">
                      ResistantAI data
                    </span>
                  </div>
                </p>
              </div>
            </div>
          </Columns>
        </Fade>
      </Section>

      <Section sectionClass="Homepage__oversight patternedGray">
        <div className="w-wrap">
          <div className="w1-2">
              <img alt="Identity Forensics" src={TransactionCards} />
          </div>

          <div className="w1-2">
            <h2>Identity Forensics</h2>

            <p>
              At Resistant Al, we've been using machine learning to hunt cybercriminals
              for over 15 years, and we've followed them into the field of financial crime.
              We've developed state of the art machine-learning techniques we call Identity
              Forensics to make the Al and automation systems you rely upon resilient to
              manipulation and attack.
            </p>
          </div>
        </div>
      </Section>

      <Section
        sectionClass="Homepage__products"
      >
          <div>
              <h2>What We <span className="highlighted">Offer</span></h2>
              <p>
                Smooth and instant decisions are key aspects to any modern financial service. Al systems that power such decisions are under constant attack by fraudsters capable of committing fraud with convenience and anonymity
              </p>
          </div>
          <Fade up distance="100px">
            <div className="Homepage__card-row">
              <Card
                title="Document Forensics"
                text="Detect forgeries and modifications on any document."
                svgImage={Documents}
                button={{
                  text: "See details",
                  variant: "blue",
                  link: "/products/documents",
                }}
              ></Card>

              <Card
                title="Transaction Forensics"
                text="Add a protective layer enhancing the existing Fraud, AML or scoring systems."
                svgImage={Transactions}
                button={{
                  text: "See details",
                  variant: "blue",
                  link: "/products/transactions",
                }}
              ></Card>
            </div>
          </Fade>
      </Section>
      <Section sectionClass="Homepage__video">
        <Fade up distance="100px">
          <VideoSection />
        </Fade>
      </Section>
      <QuoteSection
        className="Homepage__quote"
        quotes={[
          {
            restOfQuote:
              "Resistant AI dramatically reduces false positives and detects advanced fraud and manipulation at the same time. Under the protection of Resistant AI, our Nikita engine can fully focus on credit risk assessment excellence.",
            quoteBy: "Michal Krocil",
            companyName: "Twisto",
            position: "Chief Risk Officer",
            link: "https://www.twisto.cz/",
          },
          {
            restOfQuote:
              "We use Resistant AI’s technology in our KYC process. We greatly value the insights gained to help us validate the authenticity of documents submitted by customers.",
            quoteBy: "Keren Levy",
            companyName: "Payoneer",
            position: "COO",
            link: "https://www.payoneer.com",
          },
          {
            restOfQuote:
              "Resistant AI has helped us to drastically reduce both the time it takes to catch fraud, and the amount of fraud that makes it past us to lenders.",
            quoteBy: "Matthew Willis",
            companyName: "Habito",
            position: "Financial Crime Investigator",
            link: "https://www.habito.com/",
          },
          {
            restOfQuote:
              "Resistant AI prevents the manipulation of invoices submitted to our marketplace. It allows our investors to trade in security and saves my team a huge amount of manual work.",
            quoteBy: "Alexandra Belkova",
            companyName: "Roger",
            position: "Head of Operations",
            link: "https://www.roger.cz",
          },
        ]}
      ></QuoteSection>

      {/*

      <Section className="Homepage__team">

        <div className="w-wrap">

          <div className="w1-2">
            <h2>Management <span className="highlighted">Team</span></h2>
            <p>The core of our team is comprised of research and industry experts,
              seasoned in AI and machine learning security. Our vision is to
              provide market leading technology to protect our clients from
              transaction and document fraud.
            </p>

            <div className="Homepage__team__numbers">
              <div>
                <h3>50</h3>
                <p>Team of people</p>
              </div>
              <div>
                <h3>10</h3>
                <p>PhDs in the Field of AI</p>
              </div>
              <div>
                <h3>16</h3>
                <p>Years of Experience</p>
              </div>
            </div>
          </div>

          <div className="w1-2">
            <img alt="resistant" src={LetterR} />

            <Button
              {...{
                variant: "blue",
                link: "/about",
                text: "More about us",
                size: "large",
              }}
            />
          </div>

       </div>


      </Section>

      <Section
        className="Homepage__references-media"
      >
        <div className="Homepage__media w-wrap">

          <div className="w1-3"><h2>As <span className="highlighted">seen</span> on</h2></div>
          <div className="w1-3"><a target="blank" href="https://finance.yahoo.com/news/global-aifintech200-recognises-tech-companies-135300244.html"><img alt="resistant" src={logo_media_1} /></a></div>
          <div className="w1-3"><a target="blank" href="https://venturebeat.com/2020/04/30/resistant-ai-raises-2-75-million-to-protect-algorithms-from-adversarial-attacks/"><img alt="resistant" src={logo_media_2} /></a></div>
        </div>

        <div className="Homepage__media w-wrap">
          <div className="w1-3"><a target="blank" href="https://fortune.com/2020/04/30/cybercriminals-adapt-to-coronavirus-faster-than-the-a-i-cops-hunting-them/"><img alt="resistant" src={logo_media_3} /></a></div>
          <div className="w1-3"><a target="blank" href="https://techcrunch.com/2020/04/30/index-and-credo-lead-a-2-75m-seed-in-anti-fraud-tech-resistant-ai/"><img alt="resistant" src={logo_media_4} /></a></div>
          <div className="w1-3"><a target="blank" href="https://thefintechtimes.com/resistant-ai-raises-funding-to-protect-ai-systems-from-adversarial-attacks/"><img alt="resistant" src={logo_media_5} /></a></div>
        </div>
      </Section>
      */}
    </Layout>
  )
}

export default IndexPage
