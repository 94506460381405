import React from "react"
import "./VideoSection.scss"
import playIcon from "../../svg/ico-play.svg"
import { useState } from "react"
//@ts-ignore
import video from "../../images/Resistant_AI.mp4"
import x from "../../svg/x.svg"

const VideoSection = () => {
  const [isPopupShown, setPopupShown] = useState(false)

  const handlePopupShown = () => {
    setPopupShown(prev => !prev)
  }
  return (
    <div className="VideoSection">
      {isPopupShown && (
        <>
          <div
            className="VideoSection__modal"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div>
              <video controls width="100%" height="100%">
                <source src={video}></source>
              </video>
            </div>
            <div className="VideoSection__close">
              <img
                src={x}
                alt="close"
                style={{ cursor: "pointer" }}
                onClick={handlePopupShown}
              />
            </div>
          </div>
          <div className="VideoSection__modal__overlay"></div>
        </>
      )}
      <div className="VideoSection__wrapper">
        <div className="VideoSection__icon" onClick={handlePopupShown}>
          <img src={playIcon} alt="play" />
        </div>
        <div>
          <div className="VideoSection__subtitle">
            Watch a short video about Resistant AI
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoSection
